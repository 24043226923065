define("ember-froala-editor/components/froala-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="fr-view">
    {{@content}}
    {{yield}}
  </div>
  */
  {
    "id": "0NfSYnfo",
    "block": "{\"symbols\":[\"&attrs\",\"@content\",\"&default\"],\"statements\":[[7,\"div\",false],[13,1],[12,\"class\",\"fr-view\"],[8],[0,\"\\n  \"],[1,[23,2,[]],false],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-froala-editor/components/froala-content.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
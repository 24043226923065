define("ember-changeset/helpers/changeset-set", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-changeset/utils/is-changeset"], function (_exports, _slicedToArray2, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetSet = changesetSet;
  _exports.default = void 0;

  /**
   * This is a drop in replacement for the `mut` helper
   *
   * @method changesetSet
   * @param params
   */
  function changesetSet(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        obj = _ref2[0],
        path = _ref2[1];

    if ((0, _isChangeset.default)(obj)) {
      return function (value) {
        return obj.set(path, value);
      };
    }
  }

  var _default = Ember.Helper.helper(changesetSet);

  _exports.default = _default;
});
define("ember-froala-editor/helpers/froala-html", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "@babel/runtime/helpers/esm/typeof"], function (_exports, _toConsumableArray2, _toArray2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.froalaHtml = froalaHtml;

  function froalaHtml(callback) {
    var returnSafeString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    for (var _len = arguments.length, partial = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      partial[_key - 2] = arguments[_key];
    }

    (false && !(typeof callback === 'function') && Ember.assert('{{froala-html}} helper requires a function as the first parameter', typeof callback === 'function'));
    return function froalaHtmlClosure() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      // Note: Shift editor off args so it isn't "double passed" when args are spread on the callback
      var editor = (0, _typeof2.default)(args[0]) === 'object' && args[0].component ? args.shift() : this;
      (false && !(editor.html && typeof editor.html.get === 'function') && Ember.assert('{{froala-html}} helper cannot determine the editor instance', editor.html && typeof editor.html.get === 'function')); // Get the html content from the editor, making it a SafeString if needed

      var html = returnSafeString ? Ember.String.htmlSafe(editor.html.get()) : editor.html.get(); // Call the setter, passing in the html first then all others

      return callback.apply(void 0, [html, editor].concat(partial, args));
    };
  }

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _toArray2.default)(_ref),
        callback = _ref3[0],
        partial = _ref3.slice(1);

    var _ref2$returnSafeStrin = _ref2.returnSafeString,
        returnSafeString = _ref2$returnSafeStrin === void 0 ? true : _ref2$returnSafeStrin;
    return froalaHtml.apply(void 0, [callback, returnSafeString].concat((0, _toConsumableArray2.default)(partial)));
  });

  _exports.default = _default;
});
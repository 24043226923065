define("ember-composable-helpers/helpers/sort-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      // slice params to avoid mutating the provided params
      var sortProps = params.slice();
      var array = sortProps.pop();

      var _sortProps = sortProps,
          _sortProps2 = (0, _slicedToArray2.default)(_sortProps, 1),
          firstSortProp = _sortProps2[0];

      if (Ember.typeOf(firstSortProp) === 'function' || Ember.isArray(firstSortProp)) {
        sortProps = firstSortProp;
      }

      Ember.set(this, 'array', array);
      Ember.set(this, 'sortProps', sortProps);
      return Ember.get(this, 'content');
    },
    sortPropsDidChange: Ember.observer('sortProps', function () {
      var sortProps = Ember.get(this, 'sortProps');

      if (Ember.isEmpty(sortProps)) {
        Ember.defineProperty(this, 'content', []);
      }

      if (typeof sortProps === 'function') {
        Ember.defineProperty(this, 'content', Ember.computed.sort('array', sortProps));
      } else {
        Ember.defineProperty(this, 'content', Ember.computed.sort('array', 'sortProps'));
      }
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
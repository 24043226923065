define("ember-focus-trap/modifiers/focus-trap", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "focus-trap"], function (_exports, _classCallCheck2, _focusTrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13'),
      createModifier: function createModifier() {
        return {
          focusTrapOptions: undefined,
          isActive: true,
          isPaused: false,
          shouldSelfFocus: false,
          focusTrap: undefined,
          previouslyFocusedElement: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref) {
        var _ref$named = _ref.named,
            isActive = _ref$named.isActive,
            isPaused = _ref$named.isPaused,
            shouldSelfFocus = _ref$named.shouldSelfFocus,
            focusTrapOptions = _ref$named.focusTrapOptions,
            _createFocusTrap = _ref$named._createFocusTrap;
        state.focusTrapOptions = focusTrapOptions || {};

        if (typeof isActive !== 'undefined') {
          state.isActive = isActive;
        }

        if (typeof isPaused !== 'undefined') {
          state.isPaused = isPaused;
        }

        if (state.focusTrapOptions && typeof state.focusTrapOptions.initialFocus === 'undefined' && shouldSelfFocus) {
          state.focusTrapOptions.initialFocus = element;
        }

        var createFocusTrap = _focusTrap.default; // Private to allow mocking FocusTrap in tests

        if (_createFocusTrap) {
          createFocusTrap = _createFocusTrap;
        }

        if (state.focusTrapOptions.returnFocusOnDeactivate !== false) {
          state.focusTrapOptions.returnFocusOnDeactivate = true;
        }

        if (typeof document !== 'undefined') {
          state.previouslyFocusedElement = document.activeElement;
        }

        state.focusTrap = createFocusTrap(element, state.focusTrapOptions);

        if (state.isActive) {
          state.focusTrap.activate();
        }

        if (state.isPaused) {
          state.focusTrap.pause();
        }
      },
      updateModifier: function updateModifier(state, _ref2) {
        var params = _ref2.named;
        var focusTrapOptions = params.focusTrapOptions || {};

        if (state.isActive && !params.isActive) {
          var returnFocusOnDeactivate = focusTrapOptions.returnFocusOnDeactivate;
          var returnFocus = typeof returnFocusOnDeactivate === 'undefined' ? true : false;
          state.focusTrap.deactivate({
            returnFocus: returnFocus
          });
        } else if (!state.isActive && params.isActive) {
          state.focusTrap.activate();
        }

        if (state.isPaused && !params.isPaused) {
          state.focusTrap.unpause();
        } else if (!state.isPaused && params.isPaused) {
          state.focusTrap.pause();
        } // Update state


        state.focusTrapOptions = focusTrapOptions;

        if (typeof params.isActive !== 'undefined') {
          state.isActive = params.isActive;
        }

        if (typeof params.isPaused !== 'undefined') {
          state.isPaused = params.isPaused;
        }
      },
      destroyModifier: function destroyModifier(_ref3) {
        var focusTrap = _ref3.focusTrap,
            focusTrapOptions = _ref3.focusTrapOptions,
            previouslyFocusedElement = _ref3.previouslyFocusedElement;

        // FastBoot guard https://github.com/emberjs/ember.js/issues/17949
        if (typeof FastBoot !== 'undefined') {
          return;
        }

        focusTrap.deactivate();

        if (focusTrapOptions.returnFocusOnDeactivate !== false && previouslyFocusedElement && previouslyFocusedElement.focus) {
          previouslyFocusedElement.focus();
        }
      }
    };
  }, function FocusTrapModifier() {
    (0, _classCallCheck2.default)(this, FocusTrapModifier);
  });

  _exports.default = _default;
});
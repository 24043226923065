define("ember-power-select/helpers/ember-power-select-is-group", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-power-select/utils/group-utils"], function (_exports, _slicedToArray2, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectIsGroup = emberPowerSelectIsGroup;

  function emberPowerSelectIsGroup(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        maybeGroup = _ref2[0];

    return (0, _groupUtils.isGroup)(maybeGroup);
  }

  var _default = Ember.Helper.helper(emberPowerSelectIsGroup);

  _exports.default = _default;
});
define("ember-composable-helpers/helpers/map", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          callback = _ref2[0],
          array = _ref2[1];

      Ember.set(this, 'array', array);
      Ember.set(this, 'callback', callback);
      return Ember.get(this, 'content');
    },
    byPathDidChange: Ember.observer('callback', function () {
      var callback = Ember.get(this, 'callback');

      if (Ember.isEmpty(callback)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }

      Ember.defineProperty(this, 'content', Ember.computed.map('array', callback));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
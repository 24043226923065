define("ember-ref-modifier/modifiers/ref", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/typeof"], function (_exports, _classCallCheck2, _slicedToArray2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasValidTarget(target) {
    return (0, _typeof2.default)(target) === 'object' && target !== null && !Array.isArray(target);
  }

  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }

  function getParams(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        maybeTarget = _ref2[0],
        maybePropName = _ref2[1];

    var isPropNameString = typeof maybePropName === 'string';

    if (!isPropNameString) {
      (false && !(false) && Ember.deprecate('ember-ref-modifier: {{ref "propertyName" context}} has been changed to {{ref context "propertyName"}}. Please migrate to use this.', false, {
        id: '@ember-ref-modifier--arguments-ordering-deprecation',
        until: 'v1.0.0'
      }));
    }

    return {
      propName: isPropNameString ? maybePropName : maybeTarget,
      target: isPropNameString ? maybeTarget : maybePropName
    };
  }

  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities ? Ember._modifierManagerCapabilities('3.13') : undefined,
      createModifier: function createModifier() {
        return {
          element: undefined,
          propName: undefined,
          target: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref3) {
        var positional = _ref3.positional;

        var _getParams = getParams(positional),
            propName = _getParams.propName,
            target = _getParams.target;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          Ember.set(target, propName, element);
          state.propName = propName;
          state.target = target;
        }

        state.element = element;
      },
      updateModifier: function updateModifier(state, _ref4) {
        var positional = _ref4.positional;

        var _getParams2 = getParams(positional),
            propName = _getParams2.propName,
            target = _getParams2.target;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
            if (Ember.get(target, propName) !== Ember.get(state.target, state.propName)) {
              Ember.set(state.target, state.propName, null);
            }
          }

          Ember.set(target, propName, state.element);
          state.propName = propName;
          state.target = target;
        }
      },
      destroyModifier: function destroyModifier(_ref5) {
        var target = _ref5.target,
            propName = _ref5.propName;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          Ember.set(target, propName, null);
        }
      }
    };
  }, function RefModifier() {
    (0, _classCallCheck2.default)(this, RefModifier);
  });

  _exports.default = _default;
});
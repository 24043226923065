define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "394UsASe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"id\",[23,0,[\"for\"]]],[11,\"accept\",[23,0,[\"accept\"]]],[11,\"capture\",[23,0,[\"capture\"]]],[11,\"multiple\",[23,0,[\"multiple\"]]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[10,\"hidden\",\"\"],[10,\"type\",\"file\"],[8],[9],[14,1,[[23,0,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});
define("ember-tag-input/templates/components/tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ur+dxE5K",
    "block": "{\"symbols\":[\"tag\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"tags\"]]],null,{\"statements\":[[7,\"li\",true],[10,\"class\",\"emberTagInput-tag\"],[8],[0,\"\\n    \"],[14,3,[[23,1,[]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"_isRemoveButtonVisible\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"class\",\"emberTagInput-remove\"],[3,\"action\",[[23,0,[]],\"removeTag\",[23,2,[]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9]],\"parameters\":[1,2]},null],[7,\"li\",true],[10,\"class\",\"emberTagInput-new\"],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"disabled\",[23,0,[\"readOnly\"]]],[11,\"class\",[28,\"concat\",[\"emberTagInput-input js-ember-tag-input-new\",[28,\"if\",[[24,[\"readOnly\"]],\" is-disabled\"],null]],null]],[11,\"maxlength\",[23,0,[\"maxlength\"]]],[11,\"placeholder\",[23,0,[\"placeholder\"]]],[11,\"aria-label\",[23,0,[\"ariaLabel\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tag-input/templates/components/tag-input.hbs"
    }
  });

  _exports.default = _default;
});
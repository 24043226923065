define("ember-changeset-validations/helpers/changeset", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-changeset/helpers/changeset"], function (_exports, _slicedToArray2, _changeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function changeset(_ref, options) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        obj = _ref2[0],
        validations = _ref2[1];

    return (0, _changeset.changeset)([obj, validations], options);
  }

  var _default = Ember.Helper.helper(changeset);

  _exports.default = _default;
});
define("ember-file-upload/components/file-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "syCd/Wal",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"supported\",\"active\",\"valid\"],[[23,0,[\"supported\"]],[23,0,[\"active\"]],[23,0,[\"valid\"]]]]],[23,0,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-dropzone/template.hbs"
    }
  });

  _exports.default = _default;
});
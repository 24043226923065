define("ember-changeset/utils/is-object", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isObject;

  function isObject(val) {
    return (0, _typeof2.default)(val) === 'object' || Ember.typeOf(val) === 'instance';
  }
});
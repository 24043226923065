define("ember-dynamic-format-currency/helpers/format-currency", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;

  function formatCurrency(_ref, namedArgs) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 5),
        quantity = _ref2[0],
        decimals = _ref2[1],
        sign = _ref2[2],
        separator = _ref2[3],
        decimalSeparator = _ref2[4];

    sign = sign !== undefined ? sign : namedArgs.sign !== undefined ? namedArgs.sign : "$";
    decimals = decimals !== undefined ? decimals : namedArgs.decimals !== undefined ? namedArgs.decimals : 2;
    separator = separator !== undefined ? separator : namedArgs.separator !== undefined ? namedArgs.separator : ", ";
    decimalSeparator = decimalSeparator !== undefined ? decimalSeparator : namedArgs.decimalSeparator !== undefined ? namedArgs.decimalSeparator : ".";
    var amount = parseFloat(quantity);
    var amount_rounded = amount.toFixed(decimals);
    var decimal_part = amount_rounded.toString().split('.')[1];
    var number_part = amount_rounded.toString().split('.')[0];
    var number = number_part.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return Ember.String.htmlSafe(sign.toString() + '&nbsp;' + number + decimalSeparator + decimal_part);
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});
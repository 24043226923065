define("ember-changeset/-private/err", ["exports", "@babel/runtime/helpers/esm/classCallCheck"], function (_exports, _classCallCheck2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Err = function Err(value, validation) {
    (0, _classCallCheck2.default)(this, Err);
    this.value = value;
    this.validation = validation;
  };

  _exports.default = Err;
});
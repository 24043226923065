define("ember-composable-helpers/helpers/filter-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/is-equal"], function (_exports, _slicedToArray2, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          byPath = _ref2[0],
          value = _ref2[1],
          array = _ref2[2];

      if (!Ember.isArray(array) && Ember.isArray(value)) {
        array = value;
        value = undefined;
      }

      Ember.set(this, 'array', array);
      Ember.set(this, 'byPath', byPath);
      Ember.set(this, 'value', value);
      return Ember.get(this, 'content');
    },
    byPathDidChange: Ember.observer('byPath', 'value', function () {
      var byPath = Ember.get(this, 'byPath');
      var value = Ember.get(this, 'value');

      if (Ember.isEmpty(byPath)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }

      var filterFn;

      if (Ember.isPresent(value)) {
        if (typeof value === 'function') {
          filterFn = function filterFn(item) {
            return value(Ember.get(item, byPath));
          };
        } else {
          filterFn = function filterFn(item) {
            return (0, _isEqual.default)(Ember.get(item, byPath), value);
          };
        }
      } else {
        filterFn = function filterFn(item) {
          return !!Ember.get(item, byPath);
        };
      }

      var _byPath$split = byPath.split('.'),
          _byPath$split2 = (0, _slicedToArray2.default)(_byPath$split, 1),
          minimumByPath = _byPath$split2[0];

      var cp = Ember.computed.filter("array.@each.".concat(minimumByPath), filterFn);
      Ember.defineProperty(this, 'content', cp);
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
define("ember-macro-helpers/-build-computed", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCurriedComputed = buildCurriedComputed;
  _exports.default = _default;

  function parseComputedArgs(args) {
    return {
      keys: args.slice(0, -1),
      callback: args[args.length - 1]
    };
  }

  function buildCallback(_ref) {
    var incomingCallback = _ref.incomingCallback,
        createArgs = _ref.createArgs;
    var newCallback;

    if (typeof incomingCallback === 'function') {
      newCallback = function newCallback(key) {
        return incomingCallback.apply(this, createArgs(this, key));
      };
    } else {
      newCallback = {};

      if (incomingCallback.get) {
        newCallback.get = function (key) {
          return incomingCallback.get.apply(this, createArgs(this, key));
        };
      }

      if (incomingCallback.set) {
        newCallback.set = function (key, value) {
          var _incomingCallback$set;

          return (_incomingCallback$set = incomingCallback.set).call.apply(_incomingCallback$set, [this, value].concat((0, _toConsumableArray2.default)(createArgs(this, key))));
        };
      }
    }

    return newCallback;
  }

  function _default(_ref2) {
    var collapseKeys = _ref2.collapseKeys,
        getValue = _ref2.getValue,
        flattenKeys = _ref2.flattenKeys,
        isLazy = _ref2.isLazy;
    return function () {
      var _Ember;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var _parseComputedArgs = parseComputedArgs(args),
          keys = _parseComputedArgs.keys,
          incomingCallback = _parseComputedArgs.callback;

      var collapsedKeys = collapseKeys(keys);

      function createArgs(context, key) {
        var bundledKeys = collapsedKeys.map(function (macro) {
          return {
            context: context,
            macro: macro,
            key: key
          };
        });
        var values;

        if (isLazy) {
          values = bundledKeys.slice();
          values.splice(0, 0, getValue);
        } else {
          values = bundledKeys.map(getValue);
        }

        return values;
      }

      var newCallback = buildCallback({
        incomingCallback: incomingCallback,
        createArgs: createArgs
      });
      return (_Ember = Ember).computed.apply(_Ember, (0, _toConsumableArray2.default)(flattenKeys(keys)).concat([newCallback]));
    };
  }

  function buildCurriedComputed(computed) {
    return function (callback) {
      return function () {
        return computed.apply(void 0, Array.prototype.slice.call(arguments).concat([callback]));
      };
    };
  }
});
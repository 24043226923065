define("ember-changeset/index", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray", "@babel/runtime/helpers/esm/toConsumableArray", "ember-changeset/-private/change", "ember-changeset/-private/err", "ember-changeset/utils/assign", "ember-changeset/utils/computed/inflate", "ember-changeset/utils/computed/is-empty-object", "ember-changeset/utils/computed/object-to-array", "ember-changeset/utils/computed/transform", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-object", "ember-changeset/utils/is-promise", "ember-changeset/utils/merge-nested", "ember-changeset/utils/object-without", "ember-changeset/utils/set-nested-property", "ember-changeset/utils/take", "ember-changeset/utils/validate-nested-obj", "ember-deep-set"], function (_exports, _classCallCheck2, _toArray2, _toConsumableArray2, _change, _err, _assign, _inflate, _isEmptyObject, _objectToArray, _transform, _isChangeset, _isObject, _isPromise, _mergeNested, _objectWithout, _setNestedProperty, _take, _validateNestedObj, _emberDeepSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  var keys = Object.keys;
  var CONTENT = '_content';
  var CHANGES = '_changes';
  var ERRORS = '_errors';
  var VALIDATOR = '_validator';
  var OPTIONS = '_options';
  var RUNNING_VALIDATIONS = '_runningValidations';
  var BEFORE_VALIDATION_EVENT = 'beforeValidation';
  var AFTER_VALIDATION_EVENT = 'afterValidation';
  var AFTER_ROLLBACK_EVENT = 'afterRollback';

  var defaultValidatorFn = function defaultValidatorFn() {
    return true;
  };

  var defaultOptions = {
    skipValidate: false
  };
  /**
   * Creates new changesets.
   *
   * @uses Ember.Evented
   */

  function changeset(obj) {
    var validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    var validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    (false && !(Boolean(obj)) && Ember.assert('Underlying object for changeset is missing', Boolean(obj)));
    var changeset = {
      // notifyPropertyChange: (s: string) => void,
      // trigger: (k: string, v: string | void) => void,
      __changeset__: _isChangeset.CHANGESET,
      _content: {},
      _changes: {},
      _errors: {},
      _validator: defaultValidatorFn,
      _options: defaultOptions,
      _runningValidations: {},
      _bareChanges: (0, _transform.default)(CHANGES, function (c) {
        return c.value;
      }),
      changes: (0, _objectToArray.default)(CHANGES, function (c) {
        return c.value;
      }, false),
      errors: (0, _objectToArray.default)(ERRORS, function (e) {
        return {
          value: e.value,
          validation: e.validation
        };
      }, true),
      change: (0, _inflate.default)(CHANGES, function (c) {
        return c.value;
      }),
      error: (0, _inflate.default)(ERRORS, function (e) {
        return {
          value: e.value,
          validation: e.validation
        };
      }),
      data: Ember.computed.readOnly(CONTENT),
      isValid: (0, _isEmptyObject.default)(ERRORS),
      isPristine: (0, _isEmptyObject.default)(CHANGES),
      isInvalid: Ember.computed.not('isValid').readOnly(),
      isDirty: Ember.computed.not('isPristine').readOnly(),
      init: function init() {
        var c = this;

        c._super.apply(c, arguments);

        c[CONTENT] = obj;
        c[CHANGES] = {};
        c[ERRORS] = {};
        c[VALIDATOR] = validateFn;
        c[OPTIONS] = (0, _assign.default)(defaultOptions, options);
        c[RUNNING_VALIDATIONS] = {};
      },

      /**
       * Proxies `get` to the underlying content or changed value, if present.
       */
      unknownProperty: function unknownProperty(key) {
        return this._valueFor(key);
      },

      /**
       * Stores change on the changeset.
       *
       * @method setUnknownProperty
       */
      setUnknownProperty: function setUnknownProperty(key, value) {
        var config = Ember.get(this, OPTIONS);
        var skipValidate = Ember.get(config, 'skipValidate');

        if (skipValidate) {
          var _content = Ember.get(this, CONTENT);

          var _oldValue = Ember.get(_content, key);

          this._setProperty({
            key: key,
            value: value,
            oldValue: _oldValue
          });

          return this._handleValidation(true, {
            key: key,
            value: value
          });
        }

        var content = Ember.get(this, CONTENT);
        var oldValue = Ember.get(content, key);

        this._setProperty({
          key: key,
          value: value,
          oldValue: oldValue
        });

        return this._validateKey(key, value);
      },

      /**
       * String representation for the changeset.
       */
      toString: function toString() {
        var normalisedContent = (0, _assign.default)(Ember.get(this, CONTENT), {});
        return "changeset:".concat(normalisedContent.toString());
      },

      /**
       * Provides a function to run before emitting changes to the model. The
       * callback function must return a hash in the same shape:
       *
       * ```
       * changeset
       *   .prepare((changes) => {
       *     let modified = {};
       *
       *     for (let key in changes) {
       *       modified[underscore(key)] = changes[key];
       *     }
       *
       *    return modified; // { first_name: "Jim", last_name: "Bob" }
       *  })
       *  .execute(); // execute the changes
       * ```
       *
       * @method prepare
       */
      prepare: function prepare(prepareChangesFn) {
        var changes = Ember.get(this, '_bareChanges');
        var preparedChanges = prepareChangesFn(changes);
        (false && !((0, _isObject.default)(preparedChanges)) && Ember.assert('Callback to `changeset.prepare` must return an object', (0, _isObject.default)(preparedChanges)));
        (0, _validateNestedObj.default)('preparedChanges', preparedChanges);
        var newObj = {};
        var newChanges = keys(preparedChanges).reduce(function (newObj, key) {
          newObj[key] = new _change.default(preparedChanges[key]);
          return newObj;
        }, newObj);
        Ember.set(this, CHANGES, newChanges);
        return this;
      },

      /**
       * Executes the changeset if in a valid state.
       *
       * @method execute
       */
      execute: function execute() {
        if (Ember.get(this, 'isValid') && Ember.get(this, 'isDirty')) {
          var content = Ember.get(this, CONTENT);
          var changes = Ember.get(this, CHANGES);
          keys(changes).forEach(function (key) {
            return (0, _emberDeepSet.default)(content, key, changes[key].value);
          });
        }

        return this;
      },

      /**
       * Executes the changeset and saves the underlying content.
       *
       * @method save
       * @param {Object} options optional object to pass to content save method
       */
      save: function save(options) {
        var _this = this;

        var content = Ember.get(this, CONTENT);
        var savePromise = Promise.resolve(this);
        this.execute();

        if (typeof content.save === 'function') {
          savePromise = content.save(options);
        } else if (typeof Ember.get(content, 'save') === 'function') {
          // we might be getting this off a proxy object.  For example, when a
          // belongsTo relationship (a proxy on the parent model)
          // another way would be content(belongsTo).content.save
          var saveFunc = Ember.get(content, 'save');

          if (saveFunc) {
            savePromise = saveFunc(options);
          }
        }

        return Promise.resolve(savePromise).then(function (result) {
          _this.rollback();

          return result;
        });
      },

      /**
       * Merges 2 valid changesets and returns a new changeset. Both changesets
       * must point to the same underlying object. The changeset target is the
       * origin. For example:
       *
       * ```
       * let changesetA = new Changeset(user, validatorFn);
       * let changesetB = new Changeset(user, validatorFn);
       * changesetA.set('firstName', 'Jim');
       * changesetB.set('firstName', 'Jimmy');
       * changesetB.set('lastName', 'Fallon');
       * let changesetC = changesetA.merge(changesetB);
       * changesetC.execute();
       * user.get('firstName'); // "Jimmy"
       * user.get('lastName'); // "Fallon"
       * ```
       *
       * @method merge
       */
      merge: function merge(changeset) {
        var content = Ember.get(this, CONTENT);
        (false && !((0, _isChangeset.default)(changeset)) && Ember.assert('Cannot merge with a non-changeset', (0, _isChangeset.default)(changeset)));
        (false && !(Ember.get(changeset, CONTENT) === content) && Ember.assert('Cannot merge with a changeset of different content', Ember.get(changeset, CONTENT) === content));

        if (Ember.get(this, 'isPristine') && Ember.get(changeset, 'isPristine')) {
          return this;
        }

        var c1 = Ember.get(this, CHANGES);
        var c2 = Ember.get(changeset, CHANGES);
        var e1 = Ember.get(this, ERRORS);
        var e2 = Ember.get(changeset, ERRORS);
        var newChangeset = new Changeset(content, Ember.get(this, VALIDATOR)); // ChangesetDef

        var newErrors = (0, _objectWithout.default)(keys(c2), e1);
        var newChanges = (0, _objectWithout.default)(keys(e2), c1);
        var mergedErrors = (0, _mergeNested.default)(newErrors, e2);
        var mergedChanges = (0, _mergeNested.default)(newChanges, c2);
        newChangeset[ERRORS] = mergedErrors;
        newChangeset[CHANGES] = mergedChanges;

        newChangeset._notifyVirtualProperties();

        return newChangeset;
      },

      /**
       * Returns the changeset to its pristine state, and discards changes and
       * errors.
       *
       * @method rollback
       */
      rollback: function rollback() {
        // Get keys before reset.
        var c = this;

        var keys = c._rollbackKeys(); // Reset.


        Ember.set(this, CHANGES, {});
        Ember.set(this, ERRORS, {});

        c._notifyVirtualProperties(keys);

        c.trigger(AFTER_ROLLBACK_EVENT);
        return this;
      },

      /**
       * Discards any errors, keeping only valid changes.
       *
       * @public
       * @chainable
       * @method rollbackInvalid
       * @param {String} key optional key to rollback invalid values
       * @return {Changeset}
       */
      rollbackInvalid: function rollbackInvalid(key) {
        var _this2 = this;

        var errorKeys = keys(Ember.get(this, ERRORS));

        if (key) {
          this._notifyVirtualProperties([key]);

          this._deleteKey(ERRORS, key);

          if (errorKeys.indexOf(key) > -1) {
            this._deleteKey(CHANGES, key);
          }
        } else {
          this._notifyVirtualProperties();

          Ember.set(this, ERRORS, {}); // if on CHANGES hash, rollback those as well

          errorKeys.forEach(function (errKey) {
            _this2._deleteKey(CHANGES, errKey);
          });
        }

        return this;
      },

      /**
       * Discards changes/errors for the specified properly only.
       *
       * @public
       * @chainable
       * @method rollbackProperty
       * @param {String} key key to delete off of changes and errors
       * @return {Changeset}
       */
      rollbackProperty: function rollbackProperty(key) {
        this._deleteKey(CHANGES, key);

        this._deleteKey(ERRORS, key);

        return this;
      },

      /**
       * Validates the changeset immediately against the validationMap passed in.
       * If no key is passed into this method, it will validate all fields on the
       * validationMap and set errors accordingly. Will throw an error if no
       * validationMap is present.
       *
       * @method validate
       */
      validate: function validate(key) {
        var _this3 = this;

        if (keys(validationMap).length === 0) {
          return Promise.resolve(null);
        }

        if (!Boolean(key)) {
          var maybePromise = keys(validationMap).map(function (validationKey) {
            return _this3._validateKey(validationKey, _this3._valueFor(validationKey));
          });
          return Promise.all(maybePromise);
        }

        return Promise.resolve(this._validateKey(key, this._valueFor(key)));
      },

      /**
       * Manually add an error to the changeset. If there is an existing
       * error or change for `key`, it will be overwritten.
       *
       * @method addError
       */
      addError: function addError(key, error) {
        // Construct new `Err` instance.
        var newError;

        if ((0, _isObject.default)(error) && !Array.isArray(error)) {
          (false && !(error.hasOwnProperty('value')) && Ember.assert('Error must have value.', error.hasOwnProperty('value')));
          (false && !(error.hasOwnProperty('validation')) && Ember.assert('Error must have validation.', error.hasOwnProperty('validation')));
          newError = new _err.default(error.value, error.validation);
        } else {
          newError = new _err.default(Ember.get(this, key), error);
        } // Add `key` to errors map.


        var errors = Ember.get(this, ERRORS);
        (0, _setNestedProperty.default)(errors, key, newError);
        this.notifyPropertyChange(ERRORS); // Notify that `key` has changed.

        this.notifyPropertyChange(key); // Return passed-in `error`.

        return error;
      },

      /**
       * Manually push multiple errors to the changeset as an array.
       *
       * @method pushErrors
       */
      pushErrors: function pushErrors(key) {
        var errors = Ember.get(this, ERRORS);
        var existingError = errors[key] || new _err.default(null, []);
        var validation = existingError.validation;
        var value = Ember.get(this, key);

        if (!Array.isArray(validation) && Boolean(validation)) {
          existingError.validation = [validation];
        }

        var v = existingError.validation;

        for (var _len = arguments.length, newErrors = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          newErrors[_key - 1] = arguments[_key];
        }

        validation = [].concat((0, _toConsumableArray2.default)(v), newErrors);
        var newError = new _err.default(value, validation);
        (0, _setNestedProperty.default)(errors, key, newError);
        this.notifyPropertyChange(ERRORS);
        this.notifyPropertyChange(key);
        return {
          value: value,
          validation: validation
        };
      },

      /**
       * Creates a snapshot of the changeset's errors and changes.
       *
       * @method snapshot
       */
      snapshot: function snapshot() {
        var changes = Ember.get(this, CHANGES);
        var errors = Ember.get(this, ERRORS);
        return {
          changes: keys(changes).reduce(function (newObj, key) {
            newObj[key] = changes[key].value;
            return newObj;
          }, {}),
          errors: keys(errors).reduce(function (newObj, key) {
            var e = errors[key];
            newObj[key] = {
              value: e.value,
              validation: e.validation
            };
            return newObj;
          }, {})
        };
      },

      /**
       * Restores a snapshot of changes and errors. This overrides existing
       * changes and errors.
       *
       * @method restore
       */
      restore: function restore(_ref) {
        var changes = _ref.changes,
            errors = _ref.errors;
        (0, _validateNestedObj.default)('snapshot.changes', changes);
        (0, _validateNestedObj.default)('snapshot.errors', errors);
        var newChanges = keys(changes).reduce(function (newObj, key) {
          newObj[key] = new _change.default(changes[key]);
          return newObj;
        }, {});
        var newErrors = keys(errors).reduce(function (newObj, key) {
          var e = errors[key];
          newObj[key] = new _err.default(e.value, e.validation);
          return newObj;
        }, {});
        Ember.set(this, CHANGES, newChanges);
        Ember.set(this, ERRORS, newErrors);

        this._notifyVirtualProperties();

        return this;
      },

      /**
       * Unlike `Ecto.Changeset.cast`, `cast` will take allowed keys and
       * remove unwanted keys off of the changeset. For example, this method
       * can be used to only allow specified changes through prior to saving.
       *
       * @method cast
       */
      cast: function cast() {
        var allowed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var changes = Ember.get(this, CHANGES);

        if (Array.isArray(allowed) && allowed.length === 0) {
          return this;
        }

        var changeKeys = keys(changes);
        var validKeys = changeKeys.filter(function (key) {
          return allowed.includes(key);
        });
        var casted = (0, _take.default)(changes, validKeys);
        Ember.set(this, CHANGES, casted);
        return this;
      },

      /**
       * Checks to see if async validator for a given key has not resolved.
       * If no key is provided it will check to see if any async validator is running.
       *
       * @method isValidating
       */
      isValidating: function isValidating(key) {
        var runningValidations = Ember.get(this, RUNNING_VALIDATIONS);
        var ks = keys(runningValidations);

        if (key) {
          return ks.includes(key);
        }

        return ks.length > 0;
      },

      /**
       * Validates a specific key
       *
       * @method _validateKey
       * @private
       */
      _validateKey: function _validateKey(key, value) {
        var _this4 = this;

        var content = Ember.get(this, CONTENT);
        var oldValue = Ember.get(content, key);

        var validation = this._validate(key, value, oldValue);

        this.trigger(BEFORE_VALIDATION_EVENT, key); // TODO: Address case when Promise is rejected.

        if ((0, _isPromise.default)(validation)) {
          this._setIsValidating(key, true);

          return validation.then(function (resolvedValidation) {
            _this4._setIsValidating(key, false);

            _this4.trigger(AFTER_VALIDATION_EVENT, key);

            return _this4._handleValidation(resolvedValidation, {
              key: key,
              value: value
            });
          });
        }

        var result = this._handleValidation(validation, {
          key: key,
          value: value
        });

        this.trigger(AFTER_VALIDATION_EVENT, key);
        return result;
      },

      /**
       * Takes resolved validation and adds an error or simply returns the value
       *
       * @method _handleValidation
       * @private
       */
      _handleValidation: function _handleValidation(validation, _ref2) {
        var key = _ref2.key,
            value = _ref2.value;
        var isValid = validation === true || Array.isArray(validation) && validation.length === 1 && validation[0] === true; // Happy path: remove `key` from error map.

        this._deleteKey(ERRORS, key); // Error case.


        if (!isValid) {
          return this.addError(key, {
            value: value,
            validation: validation
          });
        }

        return value;
      },

      /**
       * runs the validator with the key and value
       *
       * @method _validate
       * @private
       */
      _validate: function _validate(key, newValue, oldValue) {
        var validator = Ember.get(this, VALIDATOR);
        var content = Ember.get(this, CONTENT);

        if (typeof validator === 'function') {
          var isValid = validator({
            key: key,
            newValue: newValue,
            oldValue: oldValue,
            changes: Ember.get(this, 'change'),
            content: content
          });
          return typeof isValid === 'boolean' || Boolean(isValid) ? isValid : true;
        }

        return true;
      },

      /**
       * Sets property or error on the changeset.
       * Returns value or error
       */
      _setProperty: function _setProperty(_ref3) {
        var key = _ref3.key,
            value = _ref3.value,
            oldValue = _ref3.oldValue;
        var changes = Ember.get(this, CHANGES); // Happy path: update change map.

        if (!Ember.isEqual(oldValue, value)) {
          (0, _setNestedProperty.default)(changes, key, new _change.default(value));
        } else if (key in changes) {
          this._deleteKey(CHANGES, key);
        } // Happy path: notify that `key` was added.


        this.notifyPropertyChange(CHANGES);
        this.notifyPropertyChange(key);
      },

      /**
       * Increment or decrement the number of running validations for a
       * given key.
       */
      _setIsValidating: function _setIsValidating(key, value) {
        var running = Ember.get(this, RUNNING_VALIDATIONS);
        var count = Ember.get(running, key) || 0;

        if (!value && count === 1) {
          delete running[key];
          return;
        }

        (0, _emberDeepSet.default)(running, key, value ? count + 1 : count - 1);
      },

      /**
       * Value for change or the original value.
       */
      _valueFor: function _valueFor(key) {
        var changes = Ember.get(this, CHANGES);
        var errors = Ember.get(this, ERRORS);
        var content = Ember.get(this, CONTENT);

        if (errors.hasOwnProperty(key)) {
          var e = errors[key];
          return e.value;
        }

        var original = Ember.get(content, key);

        if (changes.hasOwnProperty(key)) {
          var c = changes[key];
          return c.value;
        } // nested thus circulate through `value` and see if match


        if (key.indexOf('.') !== -1) {
          var _key$split = key.split('.'),
              _key$split2 = (0, _toArray2.default)(_key$split),
              baseKey = _key$split2[0],
              keyParts = _key$split2.slice(1);

          if (changes.hasOwnProperty(baseKey)) {
            var value = changes[baseKey].value; // make sure to return value if not object

            if (!value) {
              return value;
            }

            var result = Ember.get(value, keyParts.join('.'));
            return result;
          }
        }

        return original;
      },

      /**
       * Notifies virtual properties set on the changeset of a change.
       * You can specify which keys are notified by passing in an array.
       *
       * @private
       * @param {Array} keys
       * @return {Void}
       */
      _notifyVirtualProperties: function _notifyVirtualProperties(keys) {
        var _this5 = this;

        if (!keys) {
          keys = this._rollbackKeys();
        }

        (keys || []).forEach(function (key) {
          return _this5.notifyPropertyChange(key);
        });
      },

      /**
       * Gets the changes and error keys.
       */
      _rollbackKeys: function _rollbackKeys() {
        var changes = Ember.get(this, CHANGES);
        var errors = Ember.get(this, ERRORS);
        return (0, _toConsumableArray2.default)(new Set([].concat((0, _toConsumableArray2.default)(keys(changes)), (0, _toConsumableArray2.default)(keys(errors)))));
      },

      /**
       * Deletes a key off an object and notifies observers.
       */
      _deleteKey: function _deleteKey(objName) {
        var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        var obj = Ember.get(this, objName);

        if (obj.hasOwnProperty(key)) {
          delete obj[key];
        }

        var c = this;
        c.notifyPropertyChange("".concat(objName, ".").concat(key));
        c.notifyPropertyChange(objName);
      },
      get: function get(key) {
        if (key.indexOf('.') > -1) {
          // pull off changes hash with full key instead of
          // breaking up key
          return this.unknownProperty(key);
        } else {
          return this._super.apply(this, arguments);
        }
      },
      set: function set(key, value) {
        if (key.indexOf('.') > -1) {
          // Adds new CHANGE and avoids ember intenals setting directly on model
          // TODO: overriding `set(changeset, )` doesnt work
          return this.setUnknownProperty(key, value);
        } else {
          return this._super.apply(this, arguments);
        }
      }
    };
    return Ember.Object.extend(Ember.Evented, changeset);
  }

  var Changeset =
  /**
   * Changeset factory
   *
   * @class Changeset
   * @constructor
   */
  function Changeset(obj) {
    var validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    var validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    (0, _classCallCheck2.default)(this, Changeset);
    return changeset(obj, validateFn, validationMap, options).create();
  };

  _exports.default = Changeset;
});
define("ember-composable-helpers/helpers/toggle", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;

  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }

    return currentIdx + 1;
  }

  function toggle(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        prop = _ref2[0],
        obj = _ref2[1],
        values = _ref2.slice(2);

    return function () {
      var currentValue = Ember.get(obj, prop);

      if (Ember.isPresent(values)) {
        var currentIdx = values.indexOf(currentValue);
        var nextIdx = nextIndex(Ember.get(values, 'length'), currentIdx);
        return Ember.set(obj, prop, values[nextIdx]);
      }

      return Ember.set(obj, prop, !currentValue);
    };
  }

  var _default = Ember.Helper.helper(toggle);

  _exports.default = _default;
});
define("ember-place-autocomplete/components/place-autocomplete-field", ["exports", "ember-place-autocomplete/templates/components/place-autocomplete-field"], function (_exports, _placeAutocompleteField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RETRY_WINDOW = 100;

  var _default = Ember.Component.extend({
    /* SERVICES
    ---------------------------------------------------------------------------*/
    placeAutocompleteManagerService: Ember.inject.service('google-place-autocomplete/manager'),

    /* COMPUTED PROPERTIES
    ---------------------------------------------------------------------------*/
    isGoogleAvailable: Ember.computed('google', function () {
      return !!this.google;
    }),
    isGoogleMapsAvailable: Ember.computed('isGoogleAvailable', function () {
      return this.isGoogleAvailable && this.google.maps;
    }),

    /* HOOKS
    ---------------------------------------------------------------------------*/

    /**
     * Set default values in component init
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._applyDefaults();

      var owner = Ember.getOwner(this);
      var google = owner.lookup('google:main');
      var navigator = owner.lookup('navigator:main');
      this.setProperties({
        google: google,
        navigator: navigator
      });
    },

    /**
     * @description Initialize component after is has been added to the DOM
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._bindDataAttributesToInput();

      this.setupComponent();
      this.get('placeAutocompleteManagerService').register();
    },

    /**
     * @description Clean up component before removing it from the DOM
     */
    willDestroy: function willDestroy() {
      if (Ember.isPresent(this.autocomplete)) {
        var google = this.google;
        this.get('placeAutocompleteManagerService').unregister();

        if (google && google.maps && google.maps.event) {
          google.maps.event.clearInstanceListeners(this.autocomplete);
          this.get('placeAutocompleteManagerService').removePlacesAutoCompleteContainersIfRequired();
        }
      }
    },

    /**
     * @description Acts as an observer an updates the autocomplete instance with any
     * updated options that have been passed into the component.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.autocomplete) {
        this.autocomplete.setOptions(this.getOptions());
      }
    },

    /**
     * @description Returns an object containing any options that are
     * to be passed to the autocomplete instance.
     * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
     */
    getOptions: function getOptions() {
      var google = this.google;
      var options = {
        types: this._typesToArray()
      };

      if (this.latLngBnds && Object.keys(this.latLngBnds).length === 2) {
        var _this$latLngBnds = this.latLngBnds,
            sw = _this$latLngBnds.sw,
            ne = _this$latLngBnds.ne;
        options.bounds = new google.maps.LatLngBounds(sw, ne);
      }

      if (this.restrictions && Object.keys(this.restrictions).length > 0) {
        options.componentRestrictions = this.restrictions;
      }

      if (this.fields) {
        options.fields = this.this._fieldsToArray();
      } else if (this.placeIdOnly) {
        options.fields = ['place_id', 'name', 'types'];
      }

      return options;
    },
    // Wait until the google library is loaded by calling this method
    // every 100ms
    setupComponent: function setupComponent() {
      var _this = this;

      if (document && this.isGoogleAvailable && this.isGoogleMapsAvailable) {
        this.setAutocomplete();

        if (this.withGeoLocate) {
          this.geolocateAndSetBounds();
        }

        this.autocomplete.addListener('place_changed', function () {
          _this.placeChanged();
        });
      } else {
        if (!this.isDestroyed && !this.isDestroying) {
          Ember.run.later(this, 'setupComponent', RETRY_WINDOW);
        }
      }
    },
    keyDown: function keyDown(e) {
      if (this.preventSubmit && Ember.isEqual(e.keyCode, 13)) {
        e.preventDefault();
      }
    },
    setAutocomplete: function setAutocomplete() {
      if (Ember.isEmpty(this.autocomplete)) {
        var inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
            google = this.google || window.google; //TODO: check how to use the inyected google object

        var autocomplete = new google.maps.places.Autocomplete(inputElement, this.getOptions());
        this.set('autocomplete', autocomplete);
      }
    },

    /**
     * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
     */
    geolocateAndSetBounds: function geolocateAndSetBounds() {
      var _this2 = this;

      var autocomplete = this.autocomplete;

      if (this.navigator && this.navigator.geolocation && Ember.isPresent(autocomplete)) {
        this.navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this2.google;
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    placeChanged: function placeChanged() {
      var place = this.autocomplete.getPlace();

      this._callCallback('placeChangedCallback', place);

      if (place[this.setValueWithProperty] !== undefined) {
        this.set('value', place[this.setValueWithProperty]);
      }
    },
    _callCallback: function _callCallback(callback, place) {
      var callbackFn = this.get(callback);

      if (Ember.isEqual(Ember.typeOf(callbackFn), 'function')) {
        callbackFn(place);
      }

      return this.bubbles ? true : false;
    },
    _toArray: function _toArray(value) {
      if (Ember.isArray(value)) {
        return value;
      } else if (Ember.typeOf(value) === 'string') {
        if (value.trim() === '') {
          return [];
        } else {
          return value.split(',');
        }
      } else {
        return [];
      }
    },
    _typesToArray: function _typesToArray() {
      return this._toArray(this.types);
    },
    _fieldsToArray: function _fieldsToArray() {
      return this._toArray(this.fields);
    },
    _applyDefaults: function _applyDefaults() {
      var defaultProperties = {
        layout: _placeAutocompleteField.default,
        disabled: false,
        inputClass: 'place-autocomplete--input',
        types: undefined,
        restrictions: {},
        tabindex: 0,
        withGeoLocate: false,
        setValueWithProperty: undefined,
        preventSubmit: false,
        placeIdOnly: false
      };

      for (var property in defaultProperties) {
        if (Ember.isBlank(this.get(property))) {
          this.set(property, defaultProperties[property]);
        }
      }
    },
    _bindDataAttributesToInput: function _bindDataAttributesToInput() {
      var _this3 = this;

      if (!window || !document) {
        return false;
      }

      var componentProperties = Object.keys(this) || [];
      var properties = componentProperties.filter(function (prop) {
        return prop.indexOf('data-') >= 0;
      });
      var input = document.getElementById(this.elementId).getElementsByTagName('input')[0];
      properties.forEach(function (property) {
        return input.setAttribute(property, _this3.get(property));
      });
      return true;
    },
    actions: {
      onBlur: function onBlur() {
        this._callCallback('onBlurCallback');
      }
    }
  });

  _exports.default = _default;
});
define("ember-changeset-validations/utils/handle-multiple-validations", ["exports", "ember-changeset/utils/is-promise"], function (_exports, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleMultipleValidations;

  /**
   * Rejects `true` values from an array of validations. Returns `true` when there
   * are no errors, or the error object if there are errors.
   *
   * @private
   * @param  {Array} validations
   * @return {Boolean|Any}
   */
  function handleValidations() {
    var validations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var rejectedValidations = Ember.A(validations).reject(function (validation) {
      return Ember.typeOf(validation) === 'boolean' && validation;
    });
    return Ember.get(rejectedValidations, 'length') === 0 || rejectedValidations;
  }
  /**
   * Handles an array of validators and returns Promise.all if any value is a
   * Promise.
   *
   * @public
   * @param  {Array} validators Array of validator functions
   * @param  {String} options.key
   * @param  {Any} options.newValue
   * @param  {Any} options.oldValue
   * @param  {Object} options.changes
   * @param  {Object} options.content
   * @return {Promise|Boolean|Any}
   */


  function handleMultipleValidations(validators, _ref) {
    var key = _ref.key,
        newValue = _ref.newValue,
        oldValue = _ref.oldValue,
        changes = _ref.changes,
        content = _ref.content;
    var validations = Ember.A(validators.map(function (validator) {
      return validator(key, newValue, oldValue, changes, content);
    }));

    if (Ember.A(validations).any(_isPromise.default)) {
      return Ember.RSVP.all(validations).then(handleValidations);
    }

    return handleValidations(validations);
  }
});
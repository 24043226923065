define("ember-changeset/-private/change", ["exports", "@babel/runtime/helpers/esm/classCallCheck"], function (_exports, _classCallCheck2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Change = function Change(value) {
    (0, _classCallCheck2.default)(this, Change);
    this.value = value;
  };

  _exports.default = Change;
});